$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 0
        }, 1000);
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-hero-slider"),
        $heroSliderContent = $(".js-hero-slider-content"),
        $heroSliderDots = $(".js-hero-slider-dots");

    if ($heroSlider.length) {
        $heroSlider.slick({
            infinite: true,
            fade: false,
            dots: true,
            appendDots: $heroSliderDots,
            arrows: false,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 7000,
            asNavFor: $heroSliderContent,
            waitForAnimate: false,
            rows: false,
            responsive: [{
                breakpoint: 700,
                settings: {
                    dots: false
                }
            }]
        });

        $heroSliderContent.slick({
            infinite: true,
            fade: true,
            dots: false,
            arrows: false,
            autoplay: false,
            speed: 1000,
            touchMove: false,
            swipe: false,
            waitForAnimate: false,
            rows: false,
            responsive: []
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textSlider
    // *
    // *
    var $textSlider = $(".js-text-slider");

    if ($textSlider.length) {
        $textSlider.slick({
            infinite: true,
            fade: false,
            dots: false,
            arrows: true,
            prevArrow: $textSlider.parents(".js-text-slider-wrapper").find(".js-text-slider-nav-prev"),
            nextArrow: $textSlider.parents(".js-text-slider-wrapper").find(".js-text-slider-nav-next"),
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 7000,
            adaptiveHeight: false,
            responsive: []
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * StickyHeader: add Class to header if window scrolls up or down
    // *
    // *
    var position = $(window).scrollTop(),
        $header = $(".js-header"),
        $navBar = $(".js-aside-nav-bar"),
        $anchorNav = $(".js-anchornav");

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            $header.addClass("sticky");
            $navBar.addClass("sticky");
        }else {
            $header.removeClass("sticky");
            $navBar.removeClass("sticky");
        }

        // check if anchorNav is out of view
        if ($anchorNav.length) {
            if (scroll >= $anchorNav.offset().top) {
                $header.addClass("show-nav");
            }else {
                $header.removeClass("show-nav");
            }
        }

        if(scroll > position) {
            //scroll down
            $header.addClass("scroll-down").removeClass("scroll-up");
            $navBar.addClass("scroll-down").removeClass("scroll-up");
        } else {
            // scroll up
            $header.addClass("scroll-up").removeClass("scroll-down");
            $navBar.addClass("scroll-up").removeClass("scroll-down");
        }

        position = scroll;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navigation
    // *
    // *
    function toggleNav() {
        $(".js-nav-button-icon, .js-nav-mobile, .js-nav").toggleClass("active");
        $('html, body').toggleClass('freeze');
        $('body').toggleClass('nav-active');
        $('.js-nav-overlay').fadeToggle();
        $('.js-header').toggleClass("nav-active");
        $(".js-header-nav").toggleClass("invisible");
    }

    $(".js-nav-button").click(function(){
        toggleNav();
    });

    $('.js-nav nav a[href*="#"]').click(function(e) {
        toggleNav();
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mobile toggle navigation for services
    // *
    // *
    $(".js-nav-toggle").click(function(){
        $(this).toggleClass("active").find(".js-nav-toggle-sub").slideToggle();
    });

    $(".js-nav-toggle").find(".active").parents(".js-nav-toggle").trigger("click");





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * timeline
    // *
    // *
    var $timeline = $(".js-timeline"),
        $navNext = $timeline.parents(".js-timeline-wrapper").find(".js-timeline-nav-next");

    if ($timeline.length) {
        $timeline.slick({
            infinite: false,
            variableWidth: true,
            fade: false,
            dots: false,
            arrows: true,
            prevArrow: $timeline.parents(".js-timeline-wrapper").find(".js-timeline-nav-prev"),
            nextArrow: $navNext,
            autoplay: false,
            speed: 1000,
            swipe: false,
            accessibility: false,
            responsive: [{
                breakpoint: 600,
                settings: {
                    swipe: true,
                }
            }]
        });

        // On before slide change
        $timeline.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $navNext.removeClass("timeline-end");

            if (nextSlide >= (slick.slideCount - 3)) {
                $navNext.addClass("timeline-end");
            }
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
    // *
    var $sections = $('[data-nav-section]'),
        $nav = $(".js-anchor-nav"),
        navHeight = $(".js-anchor-nav").outerHeight(); // 0

    $(window).on('scroll', function () {
        var cur_pos = $(this).scrollTop() + 60;

        $sections.each(function() {
            var top = $(this).offset().top - navHeight, bottom = top + $(this).outerHeight();

            if (cur_pos >= top && cur_pos <= bottom) {
                $nav.find('li').removeClass('active');
                $sections.removeClass('active');
                $(this).addClass('active');
                var key = $(this).data("nav-section");
                $nav.find('a[href*="#' + key + '"]').parent('li').addClass('active');
            }
        });
    });


});


$(window).on("load", function (e) {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * bigTeaser
    // *
    // *
    $('.js-bigteaser-wrapper').masonry({
        itemSelector: '.js-bigteaser-item',
        gutter: 120
    });

});
